<template>
    <div class="c-map-page">
        <panzoom-map
            v-if="mapHeight && mapWidth"
            :height="mapHeight"
            :width="mapWidth"
            :allowZoom="true"
            :start-center="startCenterPos"
            :minZoom="zoomRange.min > 0 ? zoomRange.min : -0"
            :maxZoom="zoomRange.max > 0 ? zoomRange.max : Infinity"
            :startZoom="pageData.StartZoom"
            :geoData="geoData"
            @scaleChanged="onScaleChanged">
                <image-entry
              :localPageData="pageData"
              v-for="(entry, index) in mapImageEntries"
              v-show="index <= layer"
              :entryData="entry"
              :key="entry.ID"
              mode="map">
              </image-entry>
        </panzoom-map>
        <div class="c-map-page--preload" v-if="preload">
            <img v-for="(entry) in mapImageEntries.filter((item, index) => index != 0)" :key="entry.ID" :src="$xp.settings.baseUrl + 'xpedeo/' + entry.Image.Url" alt="">
        </div>

    </div>
</template>

<script>
import { pageMixin } from '@xpedeo/core'
import PanzoomMap from '../PanzoomMap.vue'
import ImageEntry from '@xpedeo/core/dist/ImageEntry'
// import  from '@xpedeo/core'

export default {
  mixins: [pageMixin],
  data () {
    return {
      mapImageEntries: [],
      mapWidth: null,
      mapHeight: null,
      layersCount: 0,
      layer: 0,
      layersMinZoomLevel: [0],
      preload: false
    }
  },
  computed: {
    startCenterPos () {
      return this.pageData.StartCenterPos
    },
    zoomRange () {
      if (this.pageData.ZoomRange) { return this.pageData.ZoomRange } else return { max: 100, min: 100 }
    },
    geoData () {
      if (this.pageData.Coords) {
        return {
          topLeft: this.pageData.Coords.topLeft,
          bottomRight: this.pageData.Coords.bottomRight,
          diffNorth: this.pageData.DiffNorth,
          system: this.pageData.System
        }
      }
      return null
    }
  },
  created () {
    if (this.pageData.Sections.length > 0) {
      const entries = this.pageData.Sections[0].entries.filter(e => e.Class === 'Image')
      if (entries.length > 0) {
        this.mapImageEntries = entries
        this.layersCount = entries.length
        this.mapWidth = entries[0].Width
        this.mapHeight = entries[0].Height
        this.layersMinZoomLevel = [0]
        const zoomRange = this.zoomRange.max - this.zoomRange.min
        if (zoomRange > 0) {
          for (let i = 1; i <= this.layersCount - 1; i++) {
            this.layersMinZoomLevel.push(this.zoomRange.min + i * (zoomRange / this.layersCount))
          }
        }
      } else {
        console.warn('XpMapPage does not have any image entries.')
      }
    } else {
      console.warn('XpMapPage does not have any section.')
    }
  },
  async mounted () {
    this.$nextTick(function () {
      this.preload = true
    })

    // await this.checkPermissions()
    this.$xp.device.checkLocationSettings().then((success) => {
      console.log('sucessful', success)
      if (this.$xp.tracker) { this.$xp.tracker.installListener() }
    }, (error) => {
      console.log('not successfull, should not install listener, error: ', error)
    })
  },
  beforeDestroy () {
    if (this.$xp.tracker) { this.$xp.tracker.removeListener() }
  },
  methods: {
    async checkPermissions () {
      return new Promise(function (resolve, reject) {
        console.log('checkPermissions', process.env.VUE_APP_PLATFORM.toLowerCase())
        let permissions = []
        if (process.env.VUE_APP_PLATFORM.toLowerCase() === 'android') {
          const diagnostic = window.cordova.plugins.diagnostic

          // if (process.env.VUE_APP_IS_LOAN_DEVICE) {
          permissions = [
            // diagnostic.permission.WRITE_EXTERNAL_STORAGE,
            // diagnostic.permission.READ_EXTERNAL_STORAGE,
            diagnostic.permission.ACCESS_FINE_LOCATION,
            diagnostic.permission.ACCESS_COARSE_LOCATION
          ]

          diagnostic.isLocationEnabled(() => {
            console.log('location enabled')
            resolve()
          }, () => {
            console.log('location disabled')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          diagnostic.isGpsLocationAvailable(() => {
            console.log('gps location available')
            resolve()
          }, () => {
            console.log('gps location not available')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          diagnostic.isGpsLocationEnabled(() => {
            console.log('gps location enabled')
            resolve()
          }, () => {
            console.log('gps location disabled')
            diagnostic.switchToLocationSettings()
            resolve()
          })
          // if (window.XpedeoAR) {
          //   permissions.push(diagnostic.permission.CAMERA)
          // }
          // } else {
          // permissions = permissions.map(item => diagnostic.permission[item])
          // const addPermissions = [
          //   diagnostic.permission.WRITE_EXTERNAL_STORAGE,
          //   diagnostic.permission.READ_EXTERNAL_STORAGE
          // ]
          // addPermissions.forEach(element => {
          //   if (!(element in permissions)) {
          //     permissions.push(element)
          //   }
          // })
          // }
          console.log('Checking permissions', permissions)
          permissions.length === 0 && resolve()
          permissions.length && diagnostic.requestRuntimePermissions(
            function (statuses) {
              let grantedCounter = 0
              for (const permission in statuses) {
                switch (statuses[permission]) {
                  case diagnostic.permissionStatus.GRANTED:
                    console.log('Permission granted to use ' + permission)
                    grantedCounter += 1
                    break
                  case diagnostic.permissionStatus.NOT_REQUESTED:
                    console.log('Permission to use ' + permission + ' has not been requested yet')
                    break
                  case diagnostic.permissionStatus.DENIED:
                    console.log('Permission denied to use ' + permission + ' - ask again?')
                    break
                  case diagnostic.permissionStatus.DENIED_ALWAYS:
                    console.log('Permission permanently denied to use ' + permission + ' - guess we won\'t be using it then!')
                    break
                }
              }
              if (grantedCounter === Object.keys(statuses).length) {
                resolve()
              } else {
                reject(new Error(`Permissions were not granted. ${Object.keys(statuses).length} were requested but ${grantedCounter} were granted.`))
              }
            },
            function (error) {
              reject(new Error(error))
            },
            permissions)
        } else if (this.$xp.device.platform === 'browser') {
          // function handlePermission () {

        } else {
          resolve()
        }
      })
    },
    onScaleChanged (scale) {
      console.log(scale)
      if (this.layersCount > 1) {
        for (let i = this.layersCount; i >= 0; i--) {
          if (this.layersMinZoomLevel[i] <= scale) {
            this.switchLayer(i)
            break
          }
        }
      }
    },
    switchLayer (newLayer) {
      if (newLayer !== this.layer) {
        this.layer = newLayer
      }
    }
  },
  components: {
    PanzoomMap,
    ImageEntry
  }
}
</script>
